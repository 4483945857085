<template>
  <div class="enter">
    <!-- pc -->
    <div class="header_center" v-if="!_isMobile()">
      <div class="center1">
        <div class="video contain">
          <div class="video-info">
            <div class="video-left">
              <video
                src="https://www.lumibayedu.com/media/xuanchuan.mp4"
                controls
              />
            </div>
            <div class="video-right">
              <p>
                向知是贝湾教育推出的教育服务品牌，致力于共享优质教育资源，通过总结国内外优秀教学经验和方法，为高校师生提供“教&学”数字化内容与路径，助力高校教学数字化转型。
                <br />
                向知打造了一系列“向知大师课”，将顶尖华人学者的认知框架、问题意识与学术成果，以形象直观的方式呈现，引导学习者感受学科经典与学术前沿。并基于“通识力”培养方法论，构建“向知能力培养体系”，提升学生的思维能力与社会化能力。
                <br />
                向知在充分调研高校教学需求的基础上，打造了“教&学”一体化多端数字平台，提供优质课程资源、数字化教辅工具、能力培养服务、虚拟教研室、教学管理工具等。通过健全的产品体系与精准的平台功能，覆盖课前、课中、课后全教学场景，提供完整的“教、学、测、评、管”一站式解决方案与服务，实现贯通“教&学”全周期、全过程的数据可视化。
                <br />
                由此促进学生高效学习、融会贯通，提升学生的社会化能力，尤其是职业关键能力，并产出全周期个人能力图谱，帮助学生明确未来发展方向；辅助教师开展“线上+线下”混合式教学，优化教学设计、课程资源与测评模式，提升教学与科研能力；助力高校优化人才培养方案，完善学科建设，尤其是交叉学科建设，同步实现高效便捷的教学质量管理，推进高校教学数字化转型。
                <br />
                此外，面向高校学生、教师与教学管理，向知还发起了“光计划”，包含“有光青年”、“聚光行动”、“拾光伙伴”三个维度。“光计划”以“通识力”人才培养为目的、以辅助教师教学工作为核心、以优化教学质量管理为导向，旨在帮助高校优化人才培养方案，推动通识教育与专业教育融合发展，将数字资源的静态势能转化为教育改革的动能，助力高等教育教学数字化转型落地实施。
              </p>
            </div>
          </div>
        </div>
      </div>
      <center-slot>
        <template #txt1> VISION AND MISSION </template>
        <template #txt2> 愿景和使命 </template>
      </center-slot>
      <div class="center2 contain">
        <div class="imgs">
          <div class="left">
            <img src="@/assets/learning/learning_1.1.png" />
            <p>让知识服务生活</p>
          </div>
          <div class="right">
            <img src="@/assets/learning/learning_1.2.png" />
            <p>为传播知识而来</p>
          </div>
        </div>
      </div>
      <center-slot>
        <template #txt1> Navigation System </template>
        <template #txt2> 教&学导航系统 </template>
        <template #txt3> 贝湾为高校提供教育数字化服务 </template>
      </center-slot>
      <div class="center3 contain">
        <img src="@/assets/learning/learning_2.png" />
      </div>
      <div class="center4">
        <div></div>
      </div>
      <center-slot>
        <template #txt1> GUARANTEE</template>
        <template #txt2> 安全有保障 </template>
        <template #txt3> 为学校和学生提供全面、专业的平台功能 </template>
      </center-slot>
      <div class="contain">
        <div class="center5">
          <ul>
            <li>
              <img src="@/assets/clothes/clothes_3.1.png" />
              <div class="about2">专门的安全团队</div>
              <p class="about3">
                向知有专门的安全团队，处理来自互联网的安全威胁，管理和审查数据的访问操作。
              </p>
            </li>
            <li>
              <img src="@/assets/clothes/clothes_3.2.png" />
              <div class="about2">公安部信息安全三级认证</div>
              <p class="about3">
                向知的相关平台服务，已获得国家非金融机构的安全认证—公安部信息系统安全等级保护三级认证。
              </p>
            </li>
            <li>
              <img src="@/assets/clothes/clothes_3.3.png" />
              <div class="about2">关键隐私数据加密</div>
              <p class="about3">
                关键隐私数据加密：如手机号、密码等涉及个人隐私数据，基于底层服务的数据加密算法保护。
              </p>
            </li>
            <li>
              <img src="@/assets/clothes/clothes_3.4.png" />
              <div class="about2">教育移动互联网应用程序备案</div>
              <p class="about3">
                向知APP已经通过教育移动互联网应用程序备案审核。
              </p>
            </li>
          </ul>
        </div>
      </div>
      <center-slot>
        <template #txt1> SENSE OF WORTH</template>
        <template #txt2> 价值观 </template>
      </center-slot>
      <div class="center7 contain">
        <div class="icon">
          <ul>
            <li>
              <img src="@/assets/learning/learning_4.1.png" />
              <p>质量</p>
            </li>
            <li>
              <img src="@/assets/learning/learning_4.2.png" />
              <p>责任</p>
            </li>
            <li>
              <img src="@/assets/learning/learning_4.3.png" />
              <p>包容</p>
            </li>
            <li>
              <img src="@/assets/learning/learning_4.4.png" />
              <p>远见</p>
            </li>
            <li>
              <img src="@/assets/learning/learning_4.5.png" />
              <p>探索</p>
            </li>
            <li>
              <img src="@/assets/learning/learning_4.6.png" />
              <p>成就</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <MyFooter class="footer" v-if="!_isMobile()" />
    <!-- 移动端 -->
    <div v-if="_isMobile()">
      <div class="top-img">
        <img src="@/assets/moblie/bg_01.png" />
      </div>
      <div class="moblie-box">
        <video
          src="https://www.lumibayedu.com/media/xuanchuan.mp4"
          poster="@/assets/moblie/poster.png"
          controls
        />
        <div class="video-text">
          <p>
            向知是贝湾教育推出的教育服务品牌，致力于共享优质教育资源，通过总结国内外优秀教学经验和方法，为高校师生提供“教&学”数字化内容与路径，助力高校教学数字化转型。
            <br />
            向知打造了一系列“向知大师课”，将顶尖华人学者的认知框架、问题意识与学术成果，以形象直观的方式呈现，引导学习者感受学科经典与学术前沿。并基于“通识力”培养方法论，构建“向知能力培养体系”，提升学生的思维能力与社会化能力。
            <br />
            向知在充分调研高校教学需求的基础上，打造了“教&学”一体化多端数字平台，提供优质课程资源、数字化教辅工具、能力培养服务、虚拟教研室、教学管理工具等。通过健全的产品体系与精准的平台功能，覆盖课前、课中、课后全教学场景，提供完整的“教、学、测、评、管”一站式解决方案与服务，实现贯通“教&学”全周期、全过程的数据可视化。
            <br />
            由此促进学生高效学习、融会贯通，提升学生的社会化能力，尤其是职业关键能力，并产出全周期个人能力图谱，帮助学生明确未来发展方向；辅助教师开展“线上+线下”混合式教学，优化教学设计、课程资源与测评模式，提升教学与科研能力；助力高校优化人才培养方案，完善学科建设，尤其是交叉学科建设，同步实现高效便捷的教学质量管理，推进高校教学数字化转型。
            <br />
            此外，面向高校学生、教师与教学管理，向知还发起了“光计划”，包含“有光青年”、“聚光行动”、“拾光伙伴”三个维度。“光计划”以“通识力”人才培养为目的、以辅助教师教学工作为核心、以优化教学质量管理为导向，旨在帮助高校优化人才培养方案，推动通识教育与专业教育融合发展，将数字资源的静态势能转化为教育改革的动能，助力高等教育教学数字化转型落地实施。
          </p>
        </div>
        <div class="title">愿景和使命</div>
        <div class="img-box img-box1">
          <img src="@/assets/learning/learning_1.1.png" />
          <p>让知识服务生活</p>
        </div>
        <div class="img-box">
          <img src="@/assets/learning/learning_1.2.png" />
          <p>为传播知识而来</p>
        </div>
        <div class="title">教&学导航系统</div>
        <div class="img-box">
          <img src="@/assets/learning/learning_2.png" />
        </div>
        <div class="title">安全有保障</div>
        <div class="safe-box">
          <div>
            <img src="@/assets/clothes/clothes_3.1.png" />
            <span>专门的安全团队</span>
          </div>
          <div>
            <img src="@/assets/clothes/clothes_3.2.png" />
            <span>公安部信息安全三级认证</span>
          </div>
          <div>
            <img src="@/assets/clothes/clothes_3.3.png" />
            <span>关键隐私数据加密</span>
          </div>
          <div>
            <img src="@/assets/clothes/clothes_3.4.png" />
            <span>教育移动互联网应用程序备案</span>
          </div>
        </div>
        <div class="title">价值观</div>
        <div class="img-box2">
          <div>
            <img src="@/assets/learning/learning_4.1.png" />
            <img src="@/assets/learning/learning_4.2.png" />
            <img src="@/assets/learning/learning_4.3.png" />
          </div>
          <div>
            <p>质量</p>
            <p>责任</p>
            <p>包容</p>
          </div>
          <div>
            <img src="@/assets/learning/learning_4.4.png" />
            <img src="@/assets/learning/learning_4.5.png" />
            <img src="@/assets/learning/learning_4.6.png" />
          </div>
          <div>
            <p>远见</p>
            <p>探索</p>
            <p>成就</p>
          </div>
        </div>
        <!-- <div class="code-box">
          <div>
            <img src="@/assets/code1.png"  />
            <span>扫码关注公众号</span>
          </div>
          <div>
            <img src="@/assets/code4.png"  />
            <span>企业微信号</span>
          </div>
        </div> -->
      </div>
      <!-- <div class="foot">
        <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001604"
        >湘公网安备 43019002001604号</a
      >
      <a target="_blank"
        href="https://beian.miit.gov.cn"
        >湘ICP备 18024560号-1</a
      >
      </div> -->
      <div class="down">
        <img src="@/assets/icon_logo.png" />
        <span class="text">向知APP，为传播知识而来</span>
        <span class="button" @click="download">下载</span>
      </div>
      <MyMoblieFooter class="footer" v-if="_isMobile()" />
      <div style="display: block; width: 100%; height: 1.62rem"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      downloader: null
    }
  },
  watch: {},
  mounted () {
    window.addEventListener('beforeunload', (e) => this.beforeunloadHandler(e))
  },
  methods: {
    beforeunloadHandler (e) {
      localStorage.setItem('test1', 3)
    },
    download () {
      const u = navigator.userAgent
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isAndroid) {
        // window.location.href = 'https://tiantiantongshi.com/openapp'
        window.location.href = 'xz://openapp/welcome'
        setTimeout(() => {
          const hidden =
            window.document.hidden ||
            window.document.mozHidden ||
            window.document.msHidden ||
            window.document.webkitHidden
          if (typeof hidden === 'undefined' || hidden === false) {
            // 应用宝下载地址
            window.location.href = 'https://web.lumibayedu.com/api/config/apk'
          }
        }, 2000)
      } else if (isiOS) {
        // window.location.href = 'https://h5.lumibayedu.com/openapp'
        window.location.href = 'xzlumibay://'
        this.downloader = setTimeout(() => {
          const hidden =
            window.document.hidden ||
            window.document.mozHidden ||
            window.document.msHidden ||
            window.document.webkitHidden
          if (typeof hidden === 'undefined' || hidden === false) {
            // 应用宝下载地址
            window.location.href =
              'https://itunes.apple.com/cn/app/id1547402998'
          }
        }, 3000)
      }
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', (e) =>
      this.beforeunloadHandler(e)
    )
  }
}
// function isApp () {
//   var na = navigator.userAgent.toLowerCase()
//   var isapp = na.indexOf('xz') !== -1
//   return isapp
// }
</script>

<style scoped lang="less">
.top-img {
  display: flex;
  width: 100%;
  margin-top: -1.62rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.down {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f8f3f1;
  display: flex;
  align-items: center;
  padding: 0.22rem 0.44rem;
  img {
    width: 0.84rem;
    height: 0.84rem;
  }
  .text {
    flex: 1;
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-left: 0.2rem;
  }
  .button {
    display: inline-block;
    padding: 0.12rem 0.4rem;
    background: #6ac45a;
    border-radius: 6px;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
.moblie-box {
  display: flex;
  flex-flow: column;
  padding: 0 0.48rem;
  video {
    padding: 0.22rem 0;
    height: 3.9rem;
    background-size: contain;
  }
  .video-text {
    font-size: 0.32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.52rem;
  }
  .title {
    text-align: center;
    font-size: 0.4rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 0.5rem 0;
  }
  .img-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
    p {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: center;
      padding: 0.1rem;
    }
  }
  .img-box1 {
    margin-bottom: 0.5rem;
  }
  .safe-box {
    display: flex;
    flex-flow: column;

    div {
      display: flex;
      align-items: center;
      padding: 0.1rem;
    }
    img {
      width: 0.88rem;
      height: 0.88rem;
    }
    span {
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding-left: 0.2rem;
    }
  }
  .img-box2 {
    display: flex;
    flex-flow: column;
    div {
      display: flex;
      align-content: center;
      justify-content: space-around;
      padding-bottom: 0.2rem;
      img {
        width: 1.3rem;
        height: 1.3rem;
      }
      p {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #706a45;
        line-height: 20px;
        padding-bottom: 0.2rem;
      }
    }
  }
  .code-box {
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      img {
        width: 3rem;
        height: 3rem;
      }
      span {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
.foot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.66rem;
  padding: 0.2rem;
  border-top: 1px solid #efefef;
  a {
    font-size: 0.2rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c5c5c5;
    line-height: 14px;
    padding: 0 0.2rem;
    text-decoration: none;
  }
}
.header_center {
  height: 100%;
  margin-bottom: 102px;
  .center1 {
    width: 100%;
    height: 1335px;
    background: url("~@/assets/learning/learning_bg.png") no-repeat center /
      cover;
    margin-bottom: 70px;
    margin-top: -81px;
    .video {
      width: 100%;
      position: absolute;
      top: 1008px;
      .video-info {
        display: flex;
        .video-left {
          width: 454px;
          height: 268px;
          video {
            width: 466px;
            height: 280px;
            background-size: contain;
          }
        }
        .video-right {
          width: 816px;
          margin-left: 30px;
          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 21px;
            margin-top: 9px;
          }
        }
      }
    }
  }
  .center2 {
    margin: 53px 0 92px;
    .imgs {
      width: 855px;
      height: 252px;
      .left {
        float: left;
      }
      .right {
        float: right;
      }
      img {
        width: 355px;
        height: 212px;
        background-size: contain;
      }
      p {
        height: 16px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        color: #333333;
        line-height: 16px;
        text-align: center;
        margin-top: 19px;
      }
    }
  }
  .center3 {
    margin: 72px 0 100px;
    img {
      width: 950px;
      height: 525px;
      background-size: contain;
    }
  }
  .center4 {
    margin-bottom: 82px;
    div {
      background: url("~@/assets/learning/learning_img.png") no-repeat center /
        cover;
      width: 100%;
      height: 480px;
    }
  }
  .center5 {
    height: 335px;
    margin-top: 89px;
    ul {
      display: flex;
      li {
        width: 300px;
        height: 220px;
        background: #ffffff;
        border-radius: 8px;
        list-style: none;
        margin-top: 54px;
        box-shadow: 0px 2px 38px 0px #f0f0f0;
        &:hover {
          transform: scale(1.5);
          z-index: 100;
        }
        &:nth-child(2n) {
          margin-left: 6px;
          margin-right: 6px;
        }
        &:nth-child(4) {
          margin-right: 0;
        }
        img {
          width: 67px;
          height: 67px;
          background-size: contain;
          margin-left: 120px;
          margin-top: 27px;
        }
        .about2 {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .about3 {
          height: 42px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 18px;
          margin: 17px 25px 40px;
        }
      }
    }
  }
  .center6 {
    margin-top: 86px;
    margin-bottom: 87px;
    .badge {
      width: 1200px;
      height: 206px;
      ul {
        display: flex;
        &:first-child {
          margin-bottom: 34px;
        }
        li {
          width: 86px;
          height: 86px;
          background-size: contain;
          list-style: none;
          &:nth-child(2n) {
            margin: 0 73px;
          }
          &:last-child {
            margin-right: 0;
          }
          img {
            width: 86px;
            height: 86px;
            background-size: contain;
          }
        }
      }
    }
  }
  .center7 {
    margin-top: 39px;
    .icon {
      width: 936px;
      height: 110px;
      background-size: contain;
      ul {
        display: flex;
        li {
          width: 76px;
          height: 110px;
          list-style: none;
          &:nth-child(2n) {
            margin: 0 96px;
          }
          &:nth-child(6) {
            margin-right: 0;
          }
          img {
            width: 76px;
            height: 76px;
            background-size: contain;
          }
          p {
            height: 21px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #706a45;
            line-height: 21px;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
